import axios from "~/plugins/axios";
const qs = require("qs");

axios.interceptors.request.use(
  config => {
    if (!navigator.onLine) {
      this.$router.push({
        path: "/no-connection"
      });
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

export default {
  getCountyList() {
    return axios
      .get("/ebsn/api/api-iperal/geo/country-list")
      .then(data => data.data.data.values)
      .catch(error => error);
  },
  getProvinceList() {
    return axios
      .get("/ebsn/api/api-iperal/geo/province-list")
      .then(data => data.data.data.values)
      .catch(error => error);
  },
  getCityList(province) {
    return axios
      .get("/ebsn/api/api-iperal/geo/city-list", {
        params: { province: province }
      })
      .then(data => data.data.data.values)
      .catch(error => error);
  },
  lookupAddress(province, city, text) {
    return axios
      .get("/ebsn/api/api-iperal/geo/address-suggestions", {
        params: { province: province, city: city, partialAddress: text }
      })
      .then(data => data.data.data.values)
      .catch(error => error);
  },
  getBelfioreCode(cityId) {
    return axios.get("/ebsn/api/api-iperal/geo/code-belfiore", {
      params: {
        cityId
      }
    });
  },
  // getFiscalCode(name, surname, birthDate, gender, istatCode) {
  //   return axios.get("/ebsn/api/api-iperal/geo/tax-code-2", {
  //     params: {
  //       name: name,
  //       surname: surname,
  //       birthDate: birthDate,
  //       gender: gender.toUpperCase(),
  //       istatCode: istatCode
  //     }
  //   });
  // },
  // getFiscalCodeByCity(name, surname, birthDate, gender, codeBelfiore) {
  //   return axios.get("/ebsn/api/api-iperal/geo/tax-code", {
  //     params: {
  //       name: name,
  //       surname: surname,
  //       birthDate: birthDate,
  //       gender: gender.toUpperCase(),
  //       codeBelfiore
  //     }
  //   });
  // },
  getUser() {
    return axios.get("/ebsn/api/api-iperal/account/user").then(data => {
      return data.data.data.value;
    });
  },
  registrationUser(user) {
    let url = "/ebsn/api/iperal-registration/register_user";
    return axios.post(url, user).then(response => {
      return response.data;
    });
    //.catch(err => Promise.reject(err.response.data.response));
  },
  resetPassword(token, password) {
    let url = "/ebsn/api/api-iperal/account/change-password";
    return axios
      .post(url, qs.stringify({ cod_int: token, new_password: password }))
      .then(data => {
        if (data.data.response.status == 0) {
          return data.data;
        } else {
          Promise.reject(data);
        }
      });
  },
  getResetPasswordToken(email) {
    let url = "/ebsn/api/api-iperal/account/request-reset-password";
    return axios
      .get(url, {
        params: { email: email }
      })
      .then(data => {
        if (data.data.response.status == 0) {
          return data.data;
        } else {
          Promise.reject(data);
        }
      });
  },
  confirmRegistration(controlCode, newEmail) {
    let url = "/ebsn/api/iperal-registration/registration_confirm";
    return axios
      .get(url, {
        params: {
          reg_control_code: controlCode,
          new_email: newEmail
        }
      })
      .then(response => {
        return response.data.response;
      });
  },
  updateUser(user) {
    // let updatedUser = { ...user };
    // updatedUser.BirthDate = new Date(updatedUser.BirthDate).toISOString();
    return axios
      .post("/ebsn/api/api-iperal/account/update-user", user)
      .then(response => {
        if (response.data.response.status == 0) {
          return response.data.data.value;
        } else {
          return Promise.reject(response.data.response);
        }
      });
  },
  updateEmail(user, currentPassword) {
    return axios
      .post("/ebsn/api/api-iperal/account/update-email", {
        Email: user.newEmail,
        Password: currentPassword,
        UniqueId: user.UniqueID
      })
      .then(response => {
        if (response.data.response.status == 0) {
          return response.data.data.value;
        } else {
          return Promise.reject(response.data.response);
        }
      });
  },
  updatePasswordForUser(user) {
    return axios
      .post("/ebsn/api/api-iperal/account/update-credentials", {
        OldPassword: user.CurrentPassword,
        NewPassword: user.Password
      })
      .then(response => {
        if (response.data.response.status == 0) {
          return response.data.data.value;
        } else {
          return Promise.reject(response.data.response);
        }
      });
  },
  getPDVList() {
    return axios
      .get("/ebsn/api/iperal-registration/pdv_list")
      .then(response => {
        return response.data.data.pdv;
      })
      .catch(err => {
        console.log(err);
      });
  },
  updatePassword(oldPassword, newPassword) {
    let url = "/ebsn/api/api-iperal/update_password";
    return axios
      .get(url, {
        params: { old_password: oldPassword, new_password: newPassword }
      })
      .then(data => {
        return data.data;
      });
  },

  getPosteProvinceList() {
    return axios
      .get("/ebsn/api/api-iperal/poste/province-list")
      .then(data => data.data.data.values)
      .catch(error => error);
  },
  getPosteCityList(filter, idProvince) {
    return axios
      .get("/ebsn/api/api-iperal/poste/city-list", {
        params: { id_province: idProvince, filter }
      })
      .then(data => data.data.data.values)
      .catch(error => error);
  },
  getIstatCode(istatCodeProvince, idCity) {
    return axios
      .get("/ebsn/api/api-iperal/poste/get-istat-code", {
        params: { istat_code_province: istatCodeProvince, id_localita: idCity }
      })
      .then(data => data.data.data.value)
      .catch(error => error);
  },
  getPosteAddressList(filter, idCity) {
    return axios
      .get("/ebsn/api/api-iperal/poste/address-suggestions", {
        params: { id_localita: idCity, filter }
      })
      .then(data => data.data.data.values)
      .catch(error => error);
  },
  checkUser(user) {
    return axios
      .post("/ebsn/api/api-iperal/account/check-user", user)
      .then(data => {
        if (data.data.response.status == 0) {
          return data.data.data.value;
        } else {
          return Promise.reject(data.data.response);
        }
      });
  },
  getFiscalCode(
    name,
    surname,
    birthDate,
    gender,
    istatCodeProvince,
    idLocalita
  ) {
    // name=Diego&surname=Fenu&birthDate=1996-03-15&gender=M&istat_code_province=058&id_localita=5938
    try {
      return axios
        .get("/ebsn/api/api-iperal/account/generate-fiscal-code", {
          params: {
            name,
            surname,
            gender,
            birthDate,
            istat_code_province: istatCodeProvince,
            id_localita: idLocalita
          }
        })
        .then(data => {
          // console.log(data.data);
          return data.data.data.value.fiscalCode;
        });
    } catch (error) {
      return null;
    }
  },
  getIDCode(
    name,
    surname,
    birthDate,
    birthCity,
    birthCountry,
    birthZipCode,
    birthForeignProvince
  ) {
    // name=Diego&surname=Fenu&birthDate=1996-03-15&gender=M&istat_code_province=058&id_localita=5938
    try {
      return axios
        .get("/ebsn/api/api-iperal/account/get-id-code", {
          params: {
            name,
            surname,
            birthDate,
            birthCity,
            birthCountry,
            birthZipCode,
            birthForeignProvince
          }
        })
        .then(data => {
          // console.log(data.data);
          return data.data.data.value.fiscalCode;
        });
    } catch (error) {
      return null;
    }
  },

  checkCardNumber(fidelity, fiscalcode) {
    try {
      return axios
        .get("/ebsn/api/api-iperal/account/update-info-user", {
          params: {
            fidelity,
            fiscalcode
          }
        })
        .then(data => {
          // console.log(data);
          return data;
        });
    } catch (error) {
      return null;
    }
  },
  sendFacilitatedDeliveryData(formData) {
    return axios
      .post("/ebsn/api/iperal-registration/facilitated_delivery", formData)
      .then(response => {
        return response.data.response;
      });
  },
  checkIfAlreadyFacilitated(userId, registrationModuleTypeId) {
    return axios
      .get("/ebsn/api/iperal-module-controller/control", {
        params: {
          user_id: userId,
          registration_module_type_id: registrationModuleTypeId
        }
      })
      .then(response => {
        return response.data;
      });
  },
  selectAddressClass(addressId, addressClassId) {
    return axios
      .get("/ebsn/api/iperal-address-class-controller/set-address-class-id", {
        params: {
          address_id: addressId,
          address_class_id: addressClassId
        }
      })
      .then(data => {
        return data.data;
      });
  },

  sendReportingForm(data) {
    console.log(data);
    try {
      let url = "/ebsn/api/iperal-registration/reporting_form";
      return axios.post(url, data).then(response => {
        return response.data.response;
      });
    } catch (error) {
      console.log(error);
    }
  }
};
