<template>
  <v-container class="px-3 py-0 white">
    <div class="d-flex justify-space-between align-center my-5 pl-2">
      <div class="text-body-2 font-weight-bold default--text text-uppercase">
        {{ $t("links.title") }}
      </div>
    </div>
    <div>
      <div
        no-gutters
        v-for="(link, index) in links"
        v-bind:key="index"
        class="my-2 parent"
      >
        <v-btn
          text
          class="text-body-2 links default--text pl-2 links child"
          link
          :to="link.to"
        >
          {{ link.title }}
        </v-btn>
        <v-divider class="divider"></v-divider>
      </div>
      <div no-gutters class="pl-2 my-2 parent">
        <a
          link
          class="text-body-2 links default--text  links child"
          @click="openCookies()"
        >
          {{ $t("footer.links.cookies") }}
        </a>
      </div>
      <v-divider class="divider"></v-divider>
      <div no-gutters class="pl-2 my-2 parent">
        <a
          link
          class="text-decoration-none text-body-2 links default--text my-2 parent links child"
          href="https://adv.iperal.it/accessibilitaecommerce"
          @click.prevent="
            goToExternalPage('https://adv.iperal.it/accessibilitaecommerce')
          "
        >
          {{ $t("footer.links.accessibility") }}
        </a>
      </div>
      <v-divider class="divider"></v-divider>
      <div no-gutters class="py-1 my-2 parent">
        <a
          class="text-body-2 links default--text pl-2 pr-4 links child"
          style="text-decoration: none;"
          href="https://www.iperal.it/Richiamo-prodotti/"
          @click.prevent="
            goToExternalPage('https://www.iperal.it/Richiamo-prodotti/')
          "
          >Richiamo prodotti</a
        >
      </div>
    </div>
    <div class="d-flex justify-space-between align-center mtb-1 pl-2">
      <div class="text-body-2 font-weight-bold default--text text-uppercase">
        SERVIZIO CLIENTI
      </div>
    </div>
    <div class="pl-2 pr-10">
      <div class="d-flex flex-column align-start align-md-end contacts links">
        <!-- <span class="call-number">Chiamaci al Numero Verde </span> -->

        <!-- <a href="tel:800-232730" class="d-flex align-center tel-link links">
          <v-img
            src="../../../public/footer/Phone_app.svg"
            class="my-3"
            contain
            height="20"
          />
          <strong class="ml-2">800-232730</strong>
        </a> -->
        <!-- <p class="text-left text-md-right links mb-1">
          Gli orari del nostro servizio clienti sono: <br />
          Dal lunedì al sabato: 9:00 - 18:00 <br />
          <a class="links" href="mailto:servizioclienti@iperal.it">
            servizioclienti@iperal.it
          </a>
        </p> -->
        <p class="text-left text-md-right customer-sub">
          Hai bisogno di aiuto?<br />
        </p>
        <v-btn
          color="white"
          text
          rounded
          class="customer-link"
          link
          to="/servizio-clienti"
        >
          Contattaci
        </v-btn>
      </div>
    </div>
    <div class="d-flex justify-start align-center">
      <!-- Logo Facebook -->
      <a
        aria-label="Trovaci su Facebook"
        href="https://www.facebook.com/Iperal/"
        @click.prevent="goToExternalPage('https://www.facebook.com/Iperal/')"
      >
        <v-img
          src="/footer/facebook_app.svg"
          alt="facebook"
          class="my-3 ml-4"
          contain
          height="20"
          width="20"
          max-width="20"
        />
      </a>
      <!-- Logo Instagram -->
      <a
        aria-label="Trovaci su Instagram"
        href="https://www.instagram.com/iperal_official/"
        @click.prevent="
          goToExternalPage('https://www.instagram.com/iperal_official/')
        "
      >
        <v-img
          alt="instagram"
          src="/footer/instagram_app.svg"
          class="my-3 ml-3"
          contain
          height="20"
          width="20"
          max-width="20"
        />
      </a>
      <!-- Logo Youtube -->
      <a
        aria-label="Trovaci su Youtube"
        href="https://www.youtube.com/c/IperalSpaOfficial"
        @click.prevent="
          goToExternalPage('https://www.youtube.com/c/IperalSpaOfficial')
        "
      >
        <v-img
          alt="youtube"
          src="/footer/youtube_app.svg"
          class="my-3 ml-3"
          contain
          height="20"
          width="20"
          max-width="20"
        />
      </a>
    </div>
    <div class="pl-2 company mb-2 pr-10">
      <div class="company-info links">
        © IPERAL SUPERMERCATI S.P.A. con socio unico C.F./P.IVA 11023300962
        <br />
        Sede Legale: Via La Rosa, 354 - 23010 Piantedo (SO) - Sede
        Amministrativa: Via La Rosa, 354 23010 Piantedo (SO) - Tel. 0342/606811
        <br />
      </div>
    </div>
    <div class="section default--text last links" v-if="isCordova">
      <i18n path="footer.version" tag="span" class="my-1 child pl-2">
        {{ version }}
      </i18n>
    </div>
  </v-container>
</template>
<style scoped lang="scss">
.section {
  border-bottom: 1px solid #e9e9e9;
  padding: 12px 0;
  &:first-of-type {
    padding-top: 0 !important;
  }
}
.section.last {
  border-bottom: none;
}
.links {
  color: $text-color;
  font-size: 13px;
  text-transform: none;
}
.company {
  // position: absolute;
  // bottom: 12px;
  .links {
    color: $text-color;
    font-size: 11px;
    text-transform: none;
  }
}
.parent:last-child {
  .divider {
    display: none;
  }
}
.child.theme--light.v-btn--active:focus::before,
.child.theme--light.v-btn--active:hover::before,
.child.theme--light.v-btn:hover::before,
.child.theme--light.v-btn--active::before {
  // background-color: none;
  opacity: 0 !important;
}
.child {
  width: 100%;
  align-items: flex-start;
  flex-direction: column;
  .v-ripple__container {
    .v-ripple__animation {
      background: none !important;
    }
  }
}
.customer-sub {
  font-size: 14px;
}
.customer-link {
  background-color: $primary;
  text-transform: none;
}
</style>
<script>
import clickHandler from "~/mixins/clickHandler";
import page from "~/mixins/page";
export default {
  mixins: [clickHandler, page],
  data() {
    return {
      links: [
        {
          ID: 1,
          title: "Domande Frequenti",
          slug: "le-domande-piu-frequenti",
          to: { name: "FaqCategory" }
        },
        {
          ID: 2,
          title: "Regolamento",
          slug: "regolamento",
          to: { name: "Page", params: { pageName: "regolamento" } }
        },
        {
          ID: 3,
          title: "Libro ingredienti",
          slug: "i-nostri-prodotti",
          to: "/faq/i-nostri-prodotti"
        },
        {
          ID: 4,
          title: "Privacy Policy",
          slug: "privacy-policy",
          to: { name: "Page", params: { pageName: "privacy-policy" } }
        },
        {
          ID: 5,
          title: "Cookie Policy",
          slug: "cookie-policy",
          to: { name: "Page", params: { pageName: "cookie-policy" } }
        },
        {
          ID: 6,
          title: "Agevolazioni di consegna",
          slug: "consegna agevolata",
          to: { name: "ConsegnaAgevolata" }
        },
        {
          ID: 7,
          title: "Servizio Clienti",
          slug: "servizio-clienti",
          to: { name: "Servizio Clienti" }
        }
      ]
    };
  },
  computed: {
    version() {
      return global.config.version;
    }
  },
  methods: {
    onLinkClick(e) {
      this.$emit("close");
      this.$nextTick(() => {
        this.clicked(e);
      });
    },
    goToExternalPage(url) {
      if (this.$platform_is_cordova && url.indexOf("http") > -1) {
        //if app open on _system browser
        window.cordova.InAppBrowser.open(url, "_system");
      } else {
        window.open(url, "_blank");
      }
    },
    openCookies() {
      if (window.Cookiebot) {
        window.Cookiebot.show();
      }
    }
  }
};
</script>
