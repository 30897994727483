<template>
  <v-app
    :class="[
      getKeyboardOpen ? classes + ' keyboard-open' : classes,
      { 'platform-ios': platformIos }
    ]"
  >
    <Navbar v-if="showApp" />
    <v-navigation-drawer
      ref="categoryDrawer"
      v-if="showApp"
      v-model="drawerLeft"
      touchless
      left
      app
      clipped
    >
      <CategoryTree />
    </v-navigation-drawer>
    <v-navigation-drawer
      v-if="showApp"
      v-model="drawerRight"
      touchless
      fixed
      temporary
      clipped
      right
      app
      width="600"
      class="cart-drawer"
    >
      <div class="cart-info">
        <CartInfoList :isCart="true" />
      </div>

      <CartItemList />
      <CartSummary />
    </v-navigation-drawer>
    <v-navigation-drawer
      class="links-list white"
      v-if="cordovaPlatform"
      v-model="drawerLinks"
      touchless
      right
      clipped
      app
      width="auto"
      @close="drawerLinks = false"
    >
      <MenuLinksList @close="drawerLinks = false" />
    </v-navigation-drawer>
    <v-main>
      <Tutorial v-if="$platform_is_cordova" />
      <div class="content-wrap">
        <v-skeleton-loader
          type="actions, image, article,image"
          v-if="loading"
          :loading="loading"
        ></v-skeleton-loader>
        <router-view v-else></router-view>
      </div>
      <Footer v-if="showApp" />
    </v-main>
    <CookieBot></CookieBot>
    <v-fab-transition v-if="showApp">
      <v-btn
        aria-label="Vai all'inizio della pagina"
        v-scroll="onScroll"
        v-show="fab"
        fab
        fixed
        bottom
        left
        color="primary"
        @click="toTop"
        height="45"
        width="45"
        class="scroll-top"
        :class="{
          'scroll-top-with-recaptcha': recaptchaTokenEnabled
        }"
      >
        <v-icon>mdi-arrow-up</v-icon>
      </v-btn>
    </v-fab-transition>
  </v-app>
</template>
<script>
import CartSummary from "@/components/cart/CartSummary";
import CartInfoList from "@/components/cart/CartInfoList";
import CartItemList from "@/components/cart/CartItemList";
import CategoryTree from "@/components/navigation/CategoryTree";
// import CookieConsent from "@/components/CookieConsent";
import login from "~/mixins/login";
import version from "~/mixins/version";
import Tutorial from "@/components/Tutorial";
import MenuLinksList from "@/components/navigation/MenuLinksList.vue";
import CookieBot from "@/components/CookieBot.vue";

import PushNotificationService from "~/service/pushNotificationService";

import SecurityService from "~/service/securityService";

import { mapActions, mapGetters } from "vuex";
import findIndex from "lodash/findIndex";
export default {
  name: "App",
  components: {
    CategoryTree,
    CartInfoList,
    CartSummary,
    CartItemList,
    CookieBot,
    Navbar: () =>
      process.env.CORDOVA_PLATFORM
        ? import("@/components/navigation/NavbarMobile.vue")
        : import("@/components/navigation/Navbar.vue"),
    Footer: () =>
      process.env.CORDOVA_PLATFORM
        ? import("@/components/navigation/BottomNavBar.vue")
        : import("@/components/navigation/Footer.vue"), // CookieConsent,
    Tutorial,
    MenuLinksList
  },
  mixins: [login, version],
  data() {
    return {
      fab: false,
      load404: false,
      initialHref: window.location.href,
      loading: true,
      key: 1,
      classes: process.env.CORDOVA_PLATFORM ? "is-cordova" : "",
      attValue: false,
      attResponse: false,
      platformIos: false,
      keyboardOpen: false
    };
  },
  metaInfo: {
    title: "Iperal",
    titleTemplate: "%s | Iperal Spesa Online",
    meta: [
      { charset: "utf-8" },
      {
        vmid: "description",
        name: "description",
        content:
          "Iperal Spesa Online spesa online è il nuovissimo servizio di Iperal che consente di fare acquisti su un vasto assortimento di prodotti in totale comodità da casa, con semplicità e rapidità"
      },
      {
        vmid: "viewport",
        name: "viewport",
        content: "width=device-width, user-scalable=no"
      },
      { vmid: "author", name: "author", content: "Digitelematica s.r.l." }
    ]
  },
  computed: {
    ...mapGetters({
      needRestart: "needRestart"
    }),
    drawerLeft: {
      get() {
        return this.$store.state.app.drawerLeft;
      },
      set(value) {
        this.$store.commit("app/updatedDrawerLeft", value);
      }
    },
    drawerRight: {
      get() {
        return this.$store.state.app.drawerRight;
      },
      set(value) {
        this.$store.commit("app/updatedDrawerRight", value);
      }
    },
    drawerLinks: {
      get() {
        return this.$store.state.app.drawerLinks;
      },
      set(value) {
        this.$store.commit("app/updatedDrawerLinks", value);
      }
    },
    getKeyboardOpen: function() {
      return this.keyboardOpen;
    },
    showApp: function() {
      return !this.load404;
    },
    recaptchaTokenEnabled() {
      return global.config.recaptchaTokenEnabled;
    }
  },
  methods: {
    ...mapActions({
      updateAuth: "cart/updateAuth",
      resetFilters: "category/resetFilters",
      updateLastStartTime: "app/updateLastStartTime"
    }),
    updateDrawerHeight() {
      // Sets the height of the drawer element to the window height
      const drawerElement = this.$refs.categoryDrawer.$el;
      const windowHeight = window.innerHeight;
      drawerElement.style.height = `${windowHeight}px`;
    },

    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 20;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
    isPlatformIos() {
      if (this.isCordova && window.cordova.platformId.toLowerCase() === "ios") {
        this.platformIos = true;
      }
    },
    resolveRoute(data) {
      if (global.config.domainWhitelist.includes(data.host)) {
        let resolved = this.$router.resolve({
          path: data.path
        });
        if (
          resolved &&
          resolved.route.matched.length > 0 &&
          resolved.route.name != "DefaultRoute"
        ) {
          this.$router.push(resolved.route);
        } else {
          if (this.$platform_is_cordova && data.url.indexOf("http") > -1) {
            //if app open on _system browser
            window.cordova.InAppBrowser.open(data.url, "_system");
          }
        }
      } else {
        if (this.$platform_is_cordova && data.url.indexOf("http") > -1) {
          //if app open on _system browser
          window.cordova.InAppBrowser.open(data.url, "_system");
        }
      }
    },
    onAppTrackingTransparency() {
      var _this = this;
      // eslint-disable-next-line no-undef
      let platform = device ? device.platform.toLowerCase() : "ios";
      if (
        _this.$platform_is_cordova &&
        platform == "ios" &&
        window.plugins.impacTracking
      ) {
        console.log("Calling onAppTrackingTransparency");
        window.plugins.impacTracking.trackingAvailable(
          function(available) {
            if (!available) {
              console.log(
                "Tracciamento non disponibile o disabilitato dall'utente"
              );
              window.plugins.impacTracking.canRequestTracking(
                function(result) {
                  // Show results in the console
                  console.log(result);
                  if (result == true) {
                    // eslint-disable-next-line no-undef
                    Cookiebot.hide();
                    window.plugins.impacTracking.requestTracking(
                      undefined,
                      function(res) {
                        console.log("AppTracking enabled: " + res);
                        _this.attValue = res;
                        if (res == true) {
                          // eslint-disable-next-line no-undef
                          Cookiebot.submitCustomConsent(true, true, true);
                        }
                        if (res == false) {
                          // eslint-disable-next-line no-undef
                          Cookiebot.hide();
                        }
                      },
                      function(err) {
                        console.log(err);
                      }
                    );
                  } else {
                    console.log(
                      "Tracciamento non disponibile o disabilitato dall'utente"
                    );
                  }
                },
                function(err) {
                  console.log(err);
                  console.log(
                    "Tracciamento non disponibile o disabilitato dall'utente"
                  );
                }
              );
            } else {
              window.plugins.impacTracking.requestTracking(
                undefined,
                function(res) {
                  console.log(
                    "Calling onAppTrackingTransparency; already defined"
                  );
                  console.log("AppTracking enabled: " + res);
                  _this.attValue = res;
                  // eslint-disable-next-line no-undef
                  var stats = Cookiebot.consent.statistics;
                  // eslint-disable-next-line no-undef
                  var mrkg = Cookiebot.consent.marketing;
                  // eslint-disable-next-line no-undef
                  var pref = Cookiebot.consent.preferences;
                  // eslint-disable-next-line no-undef
                  Cookiebot.submitCustomConsent(pref, stats, mrkg);
                  if (res == false) {
                    // eslint-disable-next-line no-undef
                    Cookiebot.hide();
                  }
                },
                function(err) {
                  console.log(err);
                }
              );
            }
          },
          function(err) {
            console.log(err);
          }
        );
      }
    },
    async onResume() {
      var _this = this;
      var hash;
      global.EventBus.$emit("resume");
      setTimeout(async function() {
        hash = findIndex(global.config.hashNotReload, function(o) {
          return window.location.hash.indexOf(o) !== -1;
        });
        var needRestart =
          new Date().getTime() - _this.$store.state.app.lastStartTime >
          global.config.reloadAfterMillis;
        if (hash === -1 && needRestart) {
          global.EventBus.$emit("restart");
        } else {
          if (
            hash === -1 &&
            window.sessionStorage.getItem("is-authenticated")
          ) {
            await _this.loadCart();
          }
          global.EventBus.$emit("reload");
        }
        if (_this.isAuthenticated) {
          _this.refreshLogin();
        }
      }, 100);
    },
    async handleLogin() {
      this.doLogin("response419");
    },
    async onDeepLinks(path, queryString) {
      var _this = this;
      var currentQuery = {};
      console.log("onDeepLinks :::: ", path);
      console.log("onDeepLinks queryString :::: ", queryString);
      console.log("_this.$router :::: ", _this.$router);
      setTimeout(async function() {
        if (queryString) {
          var keyValPairs = queryString.split("&");
          for (var pairNum in keyValPairs) {
            var key = keyValPairs[pairNum].split("=")[0];
            if (key.length > 0) {
              if (typeof currentQuery[key] === "undefined")
                currentQuery[key] = "";
              currentQuery[key] = keyValPairs[pairNum].split("=")[1];
            }
          }
          currentQuery.q = queryString.replace("q=", "").replace(/%20/g, " ");
          console.log("onDeepLinks currentQuery :::: ", currentQuery);
          _this.$router.push({
            path: path,
            query: currentQuery
          });
          // _this.$router.push({
          //   path: "/search",
          //   name: "Search",
          //   query: currentQuery
          // });
        } else {
          _this.$router.push(path);
        }
      }, 100);
    },
    async refreshLogin() {
      if (global.config.pushNotificationEnabled) {
        console.log("REFRESH LOGIN - REGISTER FIREBASE DEVICE");
        // Al Deviceready facciamo un refresh login per controllare se l'utente è ancora collegato
        SecurityService.refreshLogin(true).then(
          function() {
            PushNotificationService.registerFirebaseDevice();
          },
          function(error) {
            console.log(error);
          }
        );
      }
    },
    ...mapActions({
      loadCart: "cart/loadCart"
    }),
    async checkInternet() {
      try {
        const response = await fetch(
          "https://captive.apple.com/probe-info.html",
          {
            method: "HEAD",
            mode: "no-cors"
          }
        );
        return response.ok || response.type === "opaque";
      } catch (error) {
        return false;
      }
    },
    async handleConnectionChange() {
      const isOnline = await this.checkInternet();
      console.log("bbb handling Connection change - ", isOnline);
      if (isOnline) {
        this.$router.push({ name: "Home" });
      } else {
        this.$router.push({ name: "NoConnection" });
      }
    }
  },
  async mounted() {
    this.updateDrawerHeight();
    window.addEventListener("resize", this.updateDrawerHeight);

    var _this = this;
    _this.load404 = false;
    try {
      if (
        window.sessionStorage.getItem("is-authenticated") ||
        window.sessionStorage.getItem("X-Ebsn-Session") ||
        window.localStorage.getItem("X-Ebsn-Account") ||
        window.location.href.indexOf("checkUser") !== -1
      ) {
        await _this.loadCart();
      }
    } finally {
      _this.loading = false;
    }

    global.EventBus.$on("isNotLoggedIn", () => {
      _this.updateAuth();
      _this.handleLogin();
    });
    global.EventBus.$on("restart", () => {
      _this.updateLastStartTime();
      window.location.reload();
    });
    global.EventBus.$on("resetFilters", () => {
      _this.resetFilters(Math.random());
    });
    window.addEventListener("online", () => {
      console.log("bbb Event fired - device ONLINE");
      _this.handleConnectionChange();
    });

    window.addEventListener("offline", () => {
      console.log("bbb Event fired - device OFFLINE");
      _this.handleConnectionChange();
    });

    setTimeout(function() {
      _this.key = _this.key + 1;
    }, 3000);
    //eventi legati a cordova, partono solo al deviceready che viene scatenato da cordova quanto la app è pronta
    document.addEventListener(
      "deviceready",
      () => {
        setTimeout(function() {
          if (window.innerWidth < 600) {
            screen.orientation.lock("portrait");
          }
        }, 1500);

        //save last start time
        _this.updateLastStartTime();

        // document.addEventListener("resume", _this.onResume, false);

        let firstResume = true;

        let resumeTimeout = null;
        document.addEventListener(
          "resume",
          () => {
            if (firstResume) {
              console.log("RESUME ::: on first resume");
              firstResume = false; // Ignore the first event triggered by some devices on device-ready
              return;
            }

            // If there is already a debounce timeout, clear it
            if (resumeTimeout) {
              clearTimeout(resumeTimeout);
            }

            // Set a new timeout to call onResume
            resumeTimeout = setTimeout(() => {
              _this.onResume();
            }, global.config.reloadAfterMillis); // Adjust delay time as needed
          },
          false
        );

        //TODO: cambiare gestione da config.js alla router clicked
        window.IonicDeeplink.route(
          global.config.deeplinks,
          function(match) {
            setTimeout(() => {
              _this.resolveRoute(match.$link);
            }, 2000);
          },
          function(nomatch) {
            setTimeout(() => {
              console.log("NOMATCH :::: ", nomatch);
              _this.resolveRoute(nomatch.$link);
            }, 2000);
          }
        );

        this.onAppTrackingTransparency();

        //con x secondi controllo se c'è una versione più recente
        this.checkVersion();

        this.isPlatformIos();

        // Plugin Keyboard
        window.addEventListener("keyboardDidHide", () => {
          // Describe your logic which will be run each time keyboard is closed.
          console.log("keyboardDidHide");
          _this.keyboardOpen = false;
        });
        window.addEventListener("keyboardDidShow", event => {
          // Describe your logic which will be run each time when keyboard is about to be shown.
          console.log(event.keyboardHeight);
          console.log("keyboardDidShow");
          _this.keyboardOpen = true;
        });
        window.addEventListener("keyboardWillShow", event => {
          // Describe your logic which will be run each time when keyboard is about to be shown.
          console.log(event.keyboardHeight);
          console.log("keyboardWillShow");
          //iOS fix
          _this.keyboardOpen = true;
        });
        window.addEventListener("keyboardWillHide", () => {
          // Describe your logic which will be run each time when keyboard is about to be closed.
          console.log("keyboardWillHide");
          //iOS fix

          _this.keyboardOpen = false;
        });

        if (_this.isAuthenticated) {
          _this.refreshLogin();
        }

        window.addEventListener(
          "CookiebotOnAccept",
          function() {
            console.log("CookiebotOnAccept");
            _this.attValue = true;
            // eslint-disable-next-line no-undef
            Cookiebot.consent;
            // eslint-disable-next-line no-undef
            console.log(Cookiebot.consent);
            // eslint-disable-next-line no-undef
            let platform = device ? device.platform.toLowerCase() : "ios";
            if (
              _this.$platform_is_cordova &&
              platform == "android" &&
              window.cordova.plugins.CookieManagementPlugin
            ) {
              window.cordova.plugins.CookieManagementPlugin.flush();
            }
          },
          false
        );

        window.addEventListener(
          "CookiebotOnDecline",
          function() {
            console.log("CookiebotOnDecline");
            _this.attValue = false;
            // eslint-disable-next-line no-undef
            Cookiebot.declined;
            // eslint-disable-next-line no-undef
            console.log(Cookiebot.declined);
            // eslint-disable-next-line no-undef
            let platform = device ? device.platform.toLowerCase() : "ios";
            if (
              _this.$platform_is_cordova &&
              platform == "android" &&
              window.cordova.plugins.CookieManagementPlugin
            ) {
              window.cordova.plugins.CookieManagementPlugin.flush();
            }
          },
          false
        );

        window.addEventListener(
          "CookiebotOnDialogDisplay",
          function() {
            console.log("Calling CookiebotOnDialogDisplay");
            // eslint-disable-next-line no-undef
            var hasResponded = Cookiebot.consented || Cookiebot.declined;
            if (
              window.cordova &&
              window.cordova.platformId.toLowerCase() == "ios" &&
              window.plugins.impacTracking &&
              !_this.attResponse
            ) {
              if (_this.attValue) {
                console.log("Ho cliccato su consenti");
                // Ho cliccato su "Consenti"
                // eslint-disable-next-line no-undef
                var stats = Cookiebot.consent.statistics;
                // eslint-disable-next-line no-undef
                var mrkg = Cookiebot.consent.marketing;
                // eslint-disable-next-line no-undef
                var pref = Cookiebot.consent.preferences;
                if (!hasResponded) {
                  console.log("Not responded");
                  // eslint-disable-next-line no-undef
                  Cookiebot.submitCustomConsent(false, false, false);
                  _this.attResponse = true;
                  // eslint-disable-next-line no-undef
                  Cookiebot.hide();
                } else {
                  console.log("Has responded");
                  // eslint-disable-next-line no-undef
                  Cookiebot.submitCustomConsent(pref, stats, mrkg);
                  _this.attResponse = true;
                }
              } else {
                console.log("Ho cliccato su non consentire");
                //Ho cliccato su "non consentire"
                // eslint-disable-next-line no-undef
                var declined = Cookiebot.declined;
                // eslint-disable-next-line no-undef
                Cookiebot.withdraw();
                _this.attResponse = true;
                if (!declined) {
                  console.log("First time");
                  //first time
                  // eslint-disable-next-line no-undef
                  Cookiebot.hide();
                }
              }
            }
          },
          false
        );
      },
      false
    );
  },
  beforeDestroy() {
    global.EventBus.$off("isNotLoggedIn");
    global.EventBus.$off("restart");
    global.EventBus.$off("resetFilters");
    window.removeEventListener("online", () => {
      this.handleConnectionChange();
    });

    window.removeEventListener("offline", () => {
      this.handleConnectionChange();
    });
  },
  watch: {
    $route(to) {
      this.load404 = false;
      if (to.meta.load404) {
        this.load404 = to.meta.load404;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.links-list {
  max-width: min-content;
}
</style>
